import React from 'react';
import { SearchBox } from 'react-instantsearch-dom';
import { Link } from 'react-router-dom';
import { Image, Input, Menu } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

// Import components.
import ApplyButton from '../ApplyButton';
import SignInButton from '../SignInButton';
import SignOutButton from '../SignOutButton';
import MyDashboardButton from '../MyDashboardButton';
import SearchInput from '../SearchInput';
import NavigationItem from './NavigationItem';
import FavoritesCart from '../Favorites/FavoritesCart';
import ChangeLanguage from '../ChangeLanguage';

// Import assets
import partnerLogo from '../../styles/img/patner/vecf-wide-logo.jpg';

const GlobalSearch = () => {
  const { t } = useTranslation();
  return <Input placeholder={t('Search location')} as={SearchBox} />;
};

const TopNavigation = ({ viewport }) => {
  if (viewport === 'mobile') {
    return (
      <Menu stackable>
        <Menu.Item>
          <SearchInput />
        </Menu.Item>

        {/* <NavigationItem as={Link} to="/" authOnly>
          Home
        </NavigationItem>
        <NavigationItem as={Link} to="/applications" authOnly>
          My Applications
        </NavigationItem>
        <NavigationItem as={Link} to="/favorites" authOnly>
          Favorite List
        </NavigationItem> */}

        <Menu.Menu position="right">
          <NavigationItem>
            <ApplyButton fluid />
          </NavigationItem>

          <NavigationItem>
            <SignInButton fluid />
          </NavigationItem>

          <NavigationItem>
            <ChangeLanguage />
          </NavigationItem>

          <NavigationItem>
            <MyDashboardButton />
          </NavigationItem>

          <NavigationItem authOnly>
            <SignOutButton fluid />
          </NavigationItem>
        </Menu.Menu>
      </Menu>
    );
  }

  return (
    <Menu stackable className="top-navigation">
      <Menu.Item>
        <Image
          as={Link}
          to="/"
          // src={partnerLogo}
          src={'https://cdec.colorado.gov/sites/cdec/files/logo.svg'}
          size="medium"
        />
      </Menu.Item>

      <Menu.Item>
        <GlobalSearch />
      </Menu.Item>

      {/* <NavigationItem as={Link} to="/" authOnly>
        Home
      </NavigationItem>
      <NavigationItem as={Link} to="/applications" authOnly>
        My Applications
      </NavigationItem>
      <NavigationItem as={Link} to="/favorites" authOnly>
        Favorite List
      </NavigationItem> */}

      <Menu.Menu position="right">
        {/* <NavigationItem>Favorites</NavigationItem> */}

        <FavoritesCart />

        <NavigationItem>
          <MyDashboardButton />
        </NavigationItem>

        <NavigationItem>
          <ChangeLanguage />
        </NavigationItem>

        <NavigationItem>
          <ApplyButton />
          &nbsp;
          <SignInButton />
          &nbsp;
          <SignOutButton />
        </NavigationItem>
      </Menu.Menu>
    </Menu>
  );
};

export default TopNavigation;
