import React from 'react';
import { Redirect, useParams } from 'react-router-dom';
import AssistanceForm from './AssistanceForm';
import ChildrenInformationForm from './ChildrenInformationForm';
import GettingStartedForm from './GettingStartedForm';
import StatisticalInformationForm from './StatisticalInformationForm';

const steps = {
  'getting-started': <GettingStartedForm />,
  assistance: <AssistanceForm />,
  'children-information': <ChildrenInformationForm />,
  'statistical-information': <StatisticalInformationForm />,
};

const ApplicationForm = () => {
  const { step } = useParams();

  if (!steps[step] && step !== undefined) {
    // Unknown step
    return <Redirect to="/404" />;
  }

  // TODO: Before loading step form, check if previous step is completed

  // Return step form or default to getting started.
  return steps[step] ?? steps['getting-started'];
};

export default ApplicationForm;
