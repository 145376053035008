import React from 'react';
import { Container, Header, Segment } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

// Import components.
import FavoriteAction from '../components/Favorites/FavoriteAction';
import FavoritesList from '../components/Favorites/FavoritesList';

const SelectProvidersPage = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <Segment basic>
        <Header as="h1">{t('Almost Done')}</Header>

        <p>
          {t(
            "Continue to look for providers and when you're ready, you can submit the application"
          )}
        </p>

        <div>
          <FavoritesList />
          &nbsp;
          <FavoriteAction submit={true} />
        </div>
      </Segment>
    </Container>
  );
};

export default SelectProvidersPage;
