import React from 'react';
import { Dropdown } from 'semantic-ui-react';

const options = [
  { key: 'en', value: 'en', text: 'English' },
  { key: 'es', value: 'es', text: 'Español' },
];

const LanguagePicker = ({ staticContext, control, children, ...rest }) => {
  return (
    <Dropdown options={options} selectOnBlur={false} clearable {...rest} />
  );
};

export default LanguagePicker;
