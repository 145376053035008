import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { Button, Form, Header, Segment, Popup, Icon } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import * as yup from 'yup';
import useApplication from '../../../hooks/useApplication';
import { showErrorMessage } from '../../../utils/form';
import FormYesNoRadio from '../../FormYesNoRadio';

const schema = yup
  .object({
    needAssistance: yup.string().required('Yes/No answer is required'),
    receiveGrants: yup.string().required('Yes/No answer is required'),
  })
  .required();

const AssistanceForm = () => {
  const history = useHistory();
  const { t } = useTranslation();

  const { applicationData: { assistance = {} } = {}, setApplicationStepData } =
    useApplication();

  const { handleSubmit, control, setValue } = useForm({
    resolver: yupResolver(schema),
  });

  // Load draft form values if any.
  React.useEffect(() => {
    Object.keys(assistance).forEach((key) => {
      setValue(key, assistance[key]);
    });
  }, [assistance, setValue]);

  const onSubmit = (data) => {
    setValue('done', true);
    setApplicationStepData('assistance', { ...data, done: true });
    history.push('/applications/new/children-information');
  };

  return (
    <Segment basic style={{ width: 650 }}>
      <Header as="h2">Assistance</Header>
      <Form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Controller
          name="needAssistance"
          control={control}
          render={({
            field: { ref, ...fieldProps },
            fieldState: { error },
          }) => (
            <FormYesNoRadio
              {...fieldProps}
              label="Do you need assistance to help pay for child care?"
              helper={
                <Popup
                  content={t(
                    'Select yes if you need recieving assistance paying for child care.'
                  )}
                  trigger={<Icon name="info circle" />}
                  position="right center"
                />
              }
              required
              error={showErrorMessage(error)}
              onChange={(vale) => {
                fieldProps.onChange(vale);
              }}
            />
          )}
        />

        <Controller
          name="receiveGrants"
          control={control}
          render={({
            field: { ref, ...fieldProps },
            fieldState: { error },
          }) => (
            <FormYesNoRadio
              {...fieldProps}
              label="Do you currently receive any grants?"
              helper={
                <Popup
                  content={t(
                    'Select yes if you recieve any grants from the Commonwealth of Virginia.'
                  )}
                  trigger={<Icon name="info circle" />}
                  position="right center"
                />
              }
              required
              error={showErrorMessage(error)}
              onChange={(vale) => {
                fieldProps.onChange(vale);
              }}
            />
          )}
        />

        <Button primary fluid>
          {t('Continue')}
        </Button>
      </Form>
    </Segment>
  );
};

export default AssistanceForm;
