import { Redirect, Route } from 'react-router-dom';
import useAuth from '../hooks/useAuth';

export function PrivateRoute({ children, ...rest }) {
  let { auth } = useAuth();
  return (
    <Route
      {...rest}
      render={({ location }) =>
        auth?.currentUser ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/sign-in',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}
