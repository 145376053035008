import React from 'react';
import { Grid, Header, Segment } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

const ApplicationsStat = () => {
  const { t } = useTranslation();

  return (
    <Grid columns="equal" stackable>
      <Grid.Row>
        <Grid.Column>
          <Segment inverted color="purple">
            <Header size="huge" content="123" />
            <div>{t('Draft applications')}</div>
          </Segment>
        </Grid.Column>

        <Grid.Column>
          <Segment inverted color="green">
            <Header size="huge" content="109" />
            <div>{t('Completed applications')}</div>
          </Segment>
        </Grid.Column>

        <Grid.Column>
          <Segment inverted color="orange">
            <Header size="huge" content="44" />
            <div>{t('Submitted applications')}</div>
          </Segment>
        </Grid.Column>

        <Grid.Column>
          <Segment inverted color="blue">
            <Header size="huge" content="23" />
            <div>{t('Abandoned applications')}</div>
          </Segment>
        </Grid.Column>

        <Grid.Column>
          <Segment inverted color="red">
            <Header size="huge" content="5" />
            <div>{t('Declined applications')}</div>
          </Segment>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default ApplicationsStat;
