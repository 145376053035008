import React, { useEffect, useState } from 'react';
import {
  collection,
  limit,
  onSnapshot,
  orderBy,
  query,
} from '@firebase/firestore';
import { upperFirst } from 'lodash-es';
import { Label } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import { useTranslation } from 'react-i18next';

// Import api, hooks and utils.
import { firestore } from '../../api/firebase';
import useAuth from '../../hooks/useAuth';
import { firebaseServerTimeToDate } from '../../utils/dates';
import styles from './Applications.module.css';

const LABEL_COLOR = {
  draft: 'purple',
  submitted: 'orange',
  'in-process': 'yellow',
  approved: 'green',
  rejected: 'red',
};

const AllApplicationList = () => {
  const { auth } = useAuth();

  const [applicationList, setApplicationList] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    if (!auth?.currentUser?.uid) return;

    const q = query(
      collection(firestore, `applications`),
      orderBy('createdAt', 'desc'),
      limit(20) // Limiting it to 20 to save real estate
    );

    // Application collection listener
    const unsubscribe = onSnapshot(q, (snapshot) => {
      if (!snapshot.empty)
        setApplicationList(
          snapshot.docs.map((doc) => {
            const {
              gettingStarted: { firstName, lastName },
            } = doc.data();

            const applicationName = `${firstName} ${lastName}'s ${t('Family')}`;
            return {
              id: doc.id,
              applicationName,
              priorityRank: 2,
              ...doc.data(),
            };
          })
        );
    });

    return () => {
      unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      // console.log('row', row);
    },
  };

  const columns = [
    {
      dataField: 'applicationName',
      text: t('Name'),
      sort: true,
      formatter: (cell, row) => {
        const { applicationId } = row;

        return <Link to={`applications/${applicationId}`}>{cell}</Link>;
      },
    },
    {
      dataField: 'status',
      text: t('Status'),
      sort: true,
      formatter: (cell, row) => {
        return <Label color={LABEL_COLOR[cell]}>{upperFirst(cell)}</Label>;
      },
    },
    {
      dataField: 'priorityRank',
      text: t('Priority Rank'),
      sort: true,
      formatter: (cell, row) => {
        return <Label circular>{cell}</Label>;
      },
    },
    {
      dataField: 'region',
      text: t('Region'),
      sort: true,
    },
    {
      dataField: 'createdAt',
      text: t('Application Date'),
      sort: true,
      formatter: (cell, row) => {
        return (
          <>
            {row?.submittedAt
              ? firebaseServerTimeToDate(
                  row?.submittedAt,
                  'MMMM Do YYYY - h:mm A'
                )
              : firebaseServerTimeToDate(
                  row?.createdAt,
                  'MMMM Do YYYY - h:mm A'
                )}
          </>
        );
      },
    },
  ];

  const SearchBox = (props) => {
    let input;

    const handleSearch = () => {
      props.onSearch(input.value);
    };

    const handleEnterPressed = (e) => {
      if (e.code === 'Enter') handleSearch();
    };

    return (
      <div className="ui action input fluid">
        <input
          ref={(n) => (input = n)}
          type="text"
          onKeyPress={handleEnterPressed}
        />
        <button className="ui button" onClick={handleSearch}>
          Search
        </button>
        <button
          className="ui button basic"
          onClick={() => {
            props.onSearch('');
            input.value = '';
          }}
        >
          Reset
        </button>
      </div>
    );
  };

  return (
    <ToolkitProvider
      bootstrap4
      keyField="id"
      data={applicationList}
      columns={columns}
      search
      exportCSV
    >
      {(props) => (
        <div className="bootstrap-iso">
          <SearchBox {...props.searchProps} />
          <hr />
          <BootstrapTable
            hover
            // striped
            headerClasses={styles.header}
            bordered={false}
            rowClasses={styles.bstRow}
            rowEvents={rowEvents}
            {...props.baseProps}
          />
        </div>
      )}
    </ToolkitProvider>
  );
};

export default AllApplicationList;
