import { isEmpty } from 'lodash-es';
import React from 'react';
import { Button, Card, Image, Rating, Item, Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import useApplication from '../../hooks/useApplication';

const FavoriteCard = ({ id, item }) => {
  const { deleteFavorite } = useApplication();
  const { t } = useTranslation();

  if (isEmpty(item)) return null;

  const meta = (
    <div>
      <div>$200 - $1800/mo</div>
      <div>{`${item?.hoursStart}:00am-${item?.hoursStart}:00pm`}</div>
      <div>{t('Family Child Care')}</div>
    </div>
  );

  return (
    <Card key={id} fluid>
      <Image src={item?.headerImageUrl} wrapped ui={false} />
      <Card.Content>
        <Card.Header as={Link} to={`/listings/${id}`}>
          <Image
            src="https://ucarecdn.com/4bbbdf2f-f26a-4026-b210-c3fbd2d59b77/-/scale_crop/13x15/"
            style={{ width: '13px' }}
          />{' '}
          {item?.name}
        </Card.Header>

        <Card.Meta content={meta} style={{ fontSize: '14px' }} />

        <Card.Description>
          <Rating color="yellow" rating={5} maxRating={5} />
        </Card.Description>
      </Card.Content>

      <Card.Content>
        <Button
          basic
          fluid
          color="red"
          onClick={(e) => {
            e.stopPropagation();
            deleteFavorite(id);
          }}
        >
          {t('Remove')}
        </Button>
      </Card.Content>
    </Card>
  );
};

const FavoriteListItem = ({ id, item, size }) => {
  const { deleteFavorite } = useApplication();
  const { t } = useTranslation();

  if (isEmpty(item)) return null;

  const meta = (
    <div style={{ fontSize: '14px' }}>
      <span>{t('$200 - $1800/mo')}</span>
      <span>{`${item?.hoursStart}:00am-${item?.hoursStart}:00pm`}</span>
      <span>{t('Family Child Care')}</span>
    </div>
  );

  return (
    <Item key={id}>
      <Item.Image
        size={size}
        src={item?.headerImageUrl}
        as={Link}
        to={`/listings/${id}`}
      />
      <Item.Content>
        <Item.Header as={Link} to={`/listings/${id}`}>
          {item?.name}
        </Item.Header>
        <Item.Meta content={meta} />
        <Item.Extra>
          <Button
            basic
            size="tiny"
            onClick={(e) => {
              e.stopPropagation();
              deleteFavorite(id);
            }}
          >
            <Icon name="trash" />
            {t('Remove')}
          </Button>
        </Item.Extra>
      </Item.Content>
    </Item>
  );
};

const FavoritesList = ({ presentation = 'card', size = 'small' }) => {
  const { favorites } = useApplication();

  if (presentation === 'card')
    return (
      <Card.Group stackable itemsPerRow={2}>
        {favorites &&
          Object.keys(favorites)?.map((key) => (
            <FavoriteCard
              key={key}
              id={key}
              item={favorites[key]}
              size={size}
            />
          ))}
      </Card.Group>
    );

  if (presentation === 'list')
    return (
      <Item.Group divided>
        {favorites &&
          Object.keys(favorites)?.map((key) => (
            <FavoriteListItem
              key={key}
              id={key}
              item={favorites[key]}
              size={size}
            />
          ))}
      </Item.Group>
    );
};

export default FavoritesList;
