import React from 'react';
import FooterNavigation from '../Navigation/FooterNavigation';

const ContentContainer = ({ fluid = false, children }) => {
  return (
    <>
      {children}

      <FooterNavigation />
    </>
  );
};

export default ContentContainer;
