import React from 'react';
import { Container, Header } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

// Import components.
import MyApplicationList from './MyApplicationList';

const Application = () => {
  const { t } = useTranslation();
  return (
    <Container>
      {/* <div>
        Applying to this program Lorem ipsum dolor sit amet, consectetur
        adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
        magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
        laboris nisi ut aliquip ex ea commodo consequat.
      </div> */}

      <Header as="h2" content={t('My Application')} />

      <MyApplicationList />
    </Container>
  );
};

export default Application;
