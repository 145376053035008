import React from 'react';
import { Grid } from 'semantic-ui-react';
import ApplicationSteps from '../components/Application/ApplicationSteps';
// import FavoritesList from '../components/Favorites/FavoritesList';
// import SectionCard from '../components/SectionCard';
import ApplicationForm from '../components/Application/forms';

const NewApplicationPage = () => {
  return (
    <Grid stackable columns={3}>
      <Grid.Column width={4}>
        <ApplicationSteps />
      </Grid.Column>

      <Grid.Column width={7}>
        <ApplicationForm />
      </Grid.Column>

      {/* <Grid.Column width={5}>
        <SectionCard header="My Favorite Programs">
          <FavoritesList presentation="list" size="tiny" />
        </SectionCard>
      </Grid.Column> */}
    </Grid>
  );
};

export default NewApplicationPage;
