import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Icon } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

const ApplyToEnrollButton = () => {
  const { t } = useTranslation();

  return (
    <Button
      icon
      basic
      fluid
      as={Link}
      to="/dashboard"
      size="large"
      className="action-button"
      labelPosition="left"
    >
      <Icon name="envelope open outline" />
      {t('Apply to Enroll')}
    </Button>
  );
};

export default ApplyToEnrollButton;
