import React from 'react';
import { Grid, Header, Segment } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import SignInSignUpForm from '../components/Forms/SignInSignUpForm';
import useAuth from '../hooks/useAuth';
import { Redirect } from 'react-router-dom';

const SignInPage = () => {
  const { isAuthenticating, isAuthenticated } = useAuth();
  const { t } = useTranslation();

  // Redirect to home if user is already logged in.
  if (!isAuthenticating && isAuthenticated) return <Redirect to="/" />;

  return (
    <Grid textAlign="center" style={{ height: '100vh' }} verticalAlign="middle">
      <Grid.Column style={{ maxWidth: 555 }}>
        <Segment basic>
          <Header as="h1" textAlign="left" content={t('Welcome back')} />
        </Segment>
        <SignInSignUpForm mode="signIn" />
      </Grid.Column>
    </Grid>
  );
};

export default SignInPage;
