import React from 'react';
import { connectInfiniteHits, Highlight } from 'react-instantsearch-dom';
import { useHistory } from 'react-router-dom';
import { Button, Card, Header, Image, Segment } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import useApplication from '../../hooks/useApplication';
// import { slugify } from '../../utils/formatters';

function CustomHits({
  hits,
  hasPrevious,
  refinePrevious,
  hasMore,
  refineNext,
}) {
  const history = useHistory();
  const { setSelectedListing } = useApplication();
  const { t } = useTranslation();

  return (
    <>
      <Card.Group stackable itemsPerRow={3}>
        {hits.map((hit) => {
          if (hit) {
            // const state = slugify(hit?.state);
            // const city = slugify(hit?.city);
            // const name = slugify(hit?.name);
            const meta = (
              <div>
                <div>{t('$200 - $1800/mo')}</div>
                <div>{`${hit?.hoursStart}:00am-${hit?.hoursStart}:00pm`}</div>
                <div>{t('Family Child Care')}</div>
              </div>
            );

            return (
              <Card
                key={hit.objectID}
                className="simple"
                onClick={() => {
                  setSelectedListing(hit);
                  history.push(`/listings/${hit.objectID}`);
                }}
              >
                <Image src={hit?.headerImageUrl} wrapped ui={false} />
                <Card.Content className="no-padding">
                  <Card.Header>
                    <Header>
                      <Image
                        src="https://ucarecdn.com/4bbbdf2f-f26a-4026-b210-c3fbd2d59b77/-/scale_crop/13x15/"
                        style={{ width: '13px' }}
                      />{' '}
                      <Highlight attribute="name" hit={hit} />
                    </Header>
                  </Card.Header>
                  <Card.Meta content={meta} style={{ fontSize: '14px' }} />

                  {/* <Card.Description>
                    <Rating color="yellow" rating={5} maxRating={5} />
                  </Card.Description> */}
                </Card.Content>
              </Card>
            );
          }

          return null;
        })}
      </Card.Group>

      {/* {hasPrevious && (
        <Button
          basic
          fluid
          disabled={!hasPrevious}
          onClick={refinePrevious}
          size="huge"
          style={{ margin: '40px 0px' }}
        >
          Show previous
        </Button>
      )} */}

      {hasMore && (
        <Segment basic textAlign="center">
          <Button
            basic
            primary
            disabled={!hasMore}
            onClick={refineNext}
            style={{ margin: '40px 0px' }}
          >
            {t('Show more')}
          </Button>
        </Segment>
      )}
    </>
  );
}

// const ListingsHits = connectHits(CustomHits);
const ListingsHits = connectInfiniteHits(CustomHits);
export default ListingsHits;
