import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { Button, Form, Header, Segment, Select } from 'semantic-ui-react';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';

import 'yup-phone';
import useApplication from '../../../hooks/useApplication';
import { showErrorMessage } from '../../../utils/form';
import CheckboxList from '../../CheckboxList';
import ChildrenField from './ChildrenField';

// Regex validate date format for MM/DD/YYYY
const dateRegex = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/;

// 24 hrs time list
const timeList = [
  { key: 1, text: '6:00 AM', value: '6:00 AM' },
  { key: 2, text: '7:00 AM', value: '7:00 AM' },
  { key: 3, text: '8:00 AM', value: '8:00 AM' },
  { key: 4, text: '9:00 AM', value: '9:00 AM' },
  { key: 5, text: '10:00 AM', value: '10:00 AM' },
  { key: 6, text: '11:00 AM', value: '11:00 AM' },
  { key: 7, text: '12:00 PM', value: '12:00 PM' },
  { key: 8, text: '1:00 PM', value: '1:00 PM' },
  { key: 9, text: '2:00 PM', value: '2:00 PM' },
  { key: 10, text: '3:00 PM', value: '3:00 PM' },
  { key: 11, text: '4:00 PM', value: '4:00 PM' },
  { key: 12, text: '5:00 PM', value: '5:00 PM' },
  { key: 13, text: '6:00 PM', value: '6:00 PM' },
  { key: 14, text: '7:00 PM', value: '7:00 PM' },
  { key: 15, text: '8:00 PM', value: '8:00 PM' },
];

const ChildrenInformationForm = () => {
  const history = useHistory();
  const { t } = useTranslation();

  const {
    applicationData: { childrenInformation = {} } = {},
    setApplicationStepData,
  } = useApplication();

  const schema = yup
    .object({
      children: yup
        .array()
        .of(
          yup.object().shape({
            firstName: yup.string().required(t('First name is required.')),
            lastName: yup.string().required(t('Last name is required.')),
            dateOfBirth: yup
              .string()
              .required(t('Date of birth is required.'))
              .matches(
                dateRegex,
                t('Invalid date format. Date must be in "MM/DD/YYYY format')
              ),

            // yup.string().required('Birth date is required.'),
            specialNeeds: yup
              .string()
              .required(t('Special needs is required.')),
          })
        )
        .required(t('At least one child information is required.')),
      dropOffTimeNeeded: yup
        .string()
        .required(t('Drop off time need is required.')),
      pickupTimeNeeded: yup
        .string()
        .required(t('Pick up time need is required.')),
      daysCareNeeded: yup
        .array()
        .min(1, t('Days care needed is required.'))
        .required(t('Days care needed is required.')),
      careCenterType: yup
        .array()
        .min(1, t('Type of care is required.'))
        .required(t('Type of care is required.')),
    })
    .required();

  const methods = useForm({
    resolver: yupResolver(schema),
  });

  // Load draft form values if any.
  React.useEffect(() => {
    Object.keys(childrenInformation).forEach((key) => {
      methods.setValue(key, childrenInformation[key]);
    });
  }, [childrenInformation, methods]);

  const onSubmit = (data) => {
    methods.setValue('done', true);
    setApplicationStepData('childrenInformation', { ...data, done: true });
    history.push('/applications/new/statistical-information');
  };

  return (
    <FormProvider {...methods}>
      <Segment basic style={{ width: 650 }}>
        <Header as="h2">{t('Children Information')}</Header>
        <Form onSubmit={methods.handleSubmit(onSubmit)} noValidate>
          <ChildrenField />

          <Controller
            name="dropOffTimeNeeded"
            control={methods.control}
            defaultValue=""
            render={({
              field: { ref, ...fieldProps },
              fieldState: { error },
            }) => (
              <Form.Field
                control={Select}
                {...fieldProps}
                required
                label={t('Drop off time needed?')}
                options={timeList}
                onChange={(e, { value }) => {
                  methods.setValue('dropOffTimeNeeded', value);
                }}
                error={showErrorMessage(error)}
              />
            )}
          />

          <Controller
            name="pickupTimeNeeded"
            control={methods.control}
            defaultValue=""
            render={({
              field: { ref, ...fieldProps },
              fieldState: { error },
            }) => (
              <Form.Field
                control={Select}
                {...fieldProps}
                required
                label={t('Pick up time needed?')}
                options={timeList}
                onChange={(e, { value }) => {
                  methods.setValue('pickupTimeNeeded', value);
                }}
                error={showErrorMessage(error)}
              />
            )}
          />

          <Controller
            name="daysCareNeeded"
            control={methods.control}
            render={({
              field: { ref, ...fieldProps },
              fieldState: { error },
            }) => (
              <CheckboxList
                {...fieldProps}
                required
                options={[
                  t('Monday'),
                  t('Tuesday'),
                  t('Wednesday'),
                  t('Thursday'),
                  t('Friday'),
                  t('Saturday'),
                  t('Sunday'),
                ]}
                label={t('Days of the week care is needed?')}
                error={showErrorMessage(error)}
              />
            )}
          />

          <Controller
            name="careCenterType"
            control={methods.control}
            render={({
              field: { ref, ...fieldProps },
              fieldState: { error },
            }) => (
              <CheckboxList
                {...fieldProps}
                required
                options={[
                  t('Licensed Child Day Center'),
                  t('Religiously Exempt Child Care Center'),
                  t('Licensed Family Care Home'),
                  t(
                    'Unlicensed, but Regulated Family Child Care Home (Voluntarily Registered, Family Day System, Locally Permitted)'
                  ),
                ]}
                label={t('What type of care would you like?')}
                error={showErrorMessage(error)}
              />
            )}
          />

          <Button primary fluid>
            {t('Continue')}
          </Button>
        </Form>
      </Segment>
    </FormProvider>
  );
};

export default ChildrenInformationForm;
