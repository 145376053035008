import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import ResponsiveContainer from './components/Layout/ResponsiveContainer';
import { PrivateRoute } from './components/PrivateRoute';
import ScrollToTop from './components/ScrollToTop';
import ApplicationsPage from './pages/ApplicationsPage';
import ApplicationDetailPage from './pages/ApplicationDetailPage';
import ConfirmationPage from './pages/ConfirmationPage';
import FavoritesPage from './pages/FavoritesPage';
import ListingsPage from './pages/ListingsPage';
import NewApplicationPage from './pages/NewApplicationPage';
import NotFoundPage from './pages/NotFoundPage';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import SelectProvidersPage from './pages/SelectProvidersPage';
import SignInPage from './pages/SignInPage';
import TermsOfUsePage from './pages/TermsOfUsePage';
import DashboardPage from './pages/DashboardPage';
import { ApplicationProvider } from './providers/ApplicationProvider';
import { AuthProvider } from './providers/AuthProvider';

function App() {
  return (
    <Router>
      <AuthProvider>
        <ApplicationProvider>
          <ResponsiveContainer>
            <ScrollToTop />

            <Switch>
              <Route path="/" exact>
                <ListingsPage />
              </Route>

              <Route path="/listings/:id?" exact>
                <ListingsPage />
              </Route>

              <PrivateRoute path="/dashboard" exact>
                <DashboardPage />
              </PrivateRoute>

              <Route path="/select-providers">
                <SelectProvidersPage />
              </Route>

              <PrivateRoute path="/applications" exact>
                <ApplicationsPage />
              </PrivateRoute>

              <PrivateRoute path="/applications/new/:step?" exact>
                <NewApplicationPage />
              </PrivateRoute>

              <PrivateRoute path="/applications/:id?" exact>
                <ApplicationDetailPage />
              </PrivateRoute>

              <PrivateRoute path="/favorites">
                <FavoritesPage />
              </PrivateRoute>

              <PrivateRoute path="/confirmation">
                <ConfirmationPage />
              </PrivateRoute>

              <Route path="/sign-in">
                <SignInPage />
              </Route>
              <Route path="/terms-of-use">
                <TermsOfUsePage />
              </Route>
              <Route path="/privacy-policy">
                <PrivacyPolicyPage />
              </Route>
              <Route component={NotFoundPage} />
            </Switch>
          </ResponsiveContainer>
        </ApplicationProvider>
      </AuthProvider>
    </Router>
  );
}

export default App;
