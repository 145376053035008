import React from 'react';

// Import components.
import FavoriteAction from './FavoriteAction';
import FavoritesList from './FavoritesList';
import SectionCard from '../SectionCard';

const Favorites = () => {
  return (
    <SectionCard header="My Favorite Programs">
      <FavoritesList presentation="list" />

      <FavoriteAction />
    </SectionCard>
  );
};

export default Favorites;
