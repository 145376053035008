import React from 'react';
import { useTranslation } from 'react-i18next';

// Import component.
import LanguagePicker from './LanguagePicker';

export default function ChangeLanguage() {
  const { i18n } = useTranslation();

  const onChange = (e, { value }) => {
    e.preventDefault();

    i18n.changeLanguage(value);
  };

  return (
    <LanguagePicker
      inline
      defaultValue={i18n.language || 'en'}
      clearable={false}
      onChange={onChange}
    />
  );
}
