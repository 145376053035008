import { Form } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

const FormYesNoRadio = ({
  required,
  value,
  name,
  label,
  helper,
  onChange,
  error,
  ...rest
}) => {
  const { t } = useTranslation();

  const handleChange = (_e, { value }) => {
    onChange(value);
  };

  return (
    <>
      <Form.Field required={required} error={error}>
        <label>
          {t(label)} {helper}
        </label>
      </Form.Field>

      <Form.Group inline error={error}>
        <Form.Radio
          label={t('Yes')}
          name={name}
          value="yes"
          checked={value === 'yes'}
          onChange={handleChange}
        />
        <Form.Radio
          label={t('No')}
          name={name}
          value="no"
          checked={value === 'no'}
          onChange={handleChange}
        />
      </Form.Group>
    </>
  );
};

export default FormYesNoRadio;
