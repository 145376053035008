import React from 'react';
import {
  CustomMarker,
  GeoSearch,
  GoogleMapsLoader,
} from 'react-instantsearch-dom-maps';

const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

const ListingsMap = ({ height }) => {
  return (
    <div style={{ height: height }}>
      <GoogleMapsLoader apiKey={GOOGLE_MAPS_API_KEY}>
        {(google) => (
          <GeoSearch google={google}>
            {({ hits }) => (
              <div>
                {hits.map((hit) => (
                  <CustomMarker key={hit.objectID} hit={hit}>
                    <img
                      width={45}
                      src="https://ucarecdn.com/e9a44feb-782f-45b6-8bfd-064af7b02d13/"
                      alt={`Map for ${hit.name}`}
                    />
                  </CustomMarker>
                ))}
              </div>
            )}
          </GeoSearch>
        )}
      </GoogleMapsLoader>
    </div>
  );
};

export default ListingsMap;
