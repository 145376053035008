import { isEmpty } from 'lodash-es';
import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Button, Card, Icon } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

// Import hooks.
import useApplication from '../../hooks/useApplication';

const FavoriteAction = ({ submit = false }) => {
  const { favorites, submitApplication } = useApplication();
  const history = useHistory();
  const { t } = useTranslation();

  if (isEmpty(favorites))
    return (
      <Card className="add-new" fluid as={Link} to="/listings">
        <Card.Content>
          <div className="card-content">
            <Card.Header as={'h4'}>
              <Icon name="search" />
            </Card.Header>
            <Card.Description>{t('Search Programs to Apply')}</Card.Description>
          </div>
        </Card.Content>
      </Card>
    );

  const count = Object.keys(favorites).length;

  return (
    <>
      <Button primary basic fluid as={Link} className="action-button" to="/">
        <Icon name="search" />
        {t('Search for Programs to Apply')}
      </Button>

      {submit && count && (
        <Button
          fluid
          primary
          className="action-button"
          onClick={async () => {
            await submitApplication();
            history.push('/confirmation');
          }}
        >
          {t('Submit My Application')}
        </Button>
      )}
    </>
  );
};

export default FavoriteAction;
