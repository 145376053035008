import React from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { Button, Form, Segment } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import { showErrorMessage } from '../../../utils/form';
import FormYesNoRadio from '../../FormYesNoRadio';
import DatePicker from '../../DatePicker';
import moment from 'moment';

const ChildrenField = () => {
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: 'children', // unique name for your Field Array
    // keyName: "id", default to "id", you can change the key name
  });
  const { t } = useTranslation();

  return (
    <>
      {fields.map((field, index) => (
        <Segment vertical clearing key={field.id}>
          <div className="field">
            <label>Child {index + 1} information</label>
            {index !== 0 && (
              <Button
                basic
                floated="right"
                type="button"
                compact
                size="mini"
                icon="minus"
                circular
                color="red"
                onClick={() => remove(index)}
              />
            )}
          </div>

          <Form.Group widths="equal">
            <Controller
              name={`children.${index}.firstName`}
              control={control}
              render={({
                field: { ref, ...fieldProps },
                fieldState: { error },
              }) => (
                <Form.Input
                  {...fieldProps}
                  required
                  label={t('First Name')}
                  placeholder={t('First Name')}
                  error={showErrorMessage(error)}
                />
              )}
            />

            <Controller
              name={`children.${index}.lastName`}
              control={control}
              render={({
                field: { ref, ...fieldProps },
                fieldState: { error },
              }) => (
                <Form.Input
                  {...fieldProps}
                  required
                  label={t('Last Name')}
                  placeholder={t('Last Name')}
                  error={showErrorMessage(error)}
                />
              )}
            />
          </Form.Group>

          <Controller
            name={`children.${index}.dateOfBirth`}
            control={control}
            render={({
              field: { ref, ...fieldProps },
              fieldState: { error },
            }) => (
              <DatePicker
                name={`children.${index}.dateOfBirth`}
                value={fieldProps.value}
                required
                label={t('Birthday')}
                placeholder={t('Select Birthday')}
                maxDate={moment()}
                error={showErrorMessage(error)}
                onChange={(_e, { value }) => {
                  fieldProps.onChange(value);
                }}
                closable
              />
            )}
          />

          <Controller
            name={`children.${index}.specialNeeds`}
            control={control}
            render={({
              field: { ref, ...fieldProps },
              fieldState: { error },
            }) => (
              <FormYesNoRadio
                {...fieldProps}
                required
                label={t('Special needs?')}
                error={showErrorMessage(error)}
              />
            )}
          />
        </Segment>
      ))}

      <Button
        type="button"
        floated="right"
        size="small"
        style={{ marginTop: 20, marginBottom: 20 }}
        fluid
        onClick={() => append({ firstName: '', lastName: '', dateOfBirth: '' })}
      >
        {t('Add Child')}
      </Button>
    </>
  );
};

export default ChildrenField;
