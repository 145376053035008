import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Link, useHistory } from 'react-router-dom';
import { Form, Message, Segment } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import useAuth from '../../hooks/useAuth';
import { showErrorMessage } from '../../utils/form';

const ErrorMessage = ({ error, mode }) => {
  if (!error?.message) return null;

  const header = mode === 'signIn' ? 'Sign In Error' : 'Sign Up Error';
  const message = error?.message ?? 'Unknown Error';
  return (
    <Message negative>
      <Message.Header>{header} </Message.Header>
      <p>{message}</p>
    </Message>
  );
};

const schema = yup
  .object({
    email: yup.string().required('Username is required'),
    password: yup.string().required('Password is required'),
  })
  .required();

const SignInSignUpForm = ({ mode, onClose }) => {
  const { authError, signIn, signUp } = useAuth();
  const { t } = useTranslation();

  const { handleSubmit, control } = useForm({
    resolver: yupResolver(schema),
  });

  const history = useHistory();

  const onSubmit = async (data) => {
    try {
      const { email, password, firstName, lastName } = data;

      // Sign in mode.
      if (mode === 'signIn') await signIn(email, password);

      // Sign up mode.
      if (mode === 'signUp') {
        const displayName = `${firstName} ${lastName}`;
        await signUp(email, password, displayName);
      }

      // No error clos modal and redirect to the previous page.
      if (typeof onClose === 'function') onClose();

      // const { from } = location.state || { from: { pathname: '/dashboard' } };
      const from = { pathname: '/dashboard' };

      history.replace(from);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)} noValidate>
      <Segment textAlign="left" stacked basic padded>
        <ErrorMessage error={authError} mode={mode} />

        {mode === 'signUp' && (
          <Form.Group widths="equal">
            <Controller
              name="firstName"
              control={control}
              defaultValue=""
              render={({
                field: { ref, ...fieldProps },
                fieldState: { error },
              }) => (
                <Form.Input
                  {...fieldProps}
                  label={t('First Name')}
                  placeholder={t('First Name')}
                  type="firstName"
                  required
                  error={showErrorMessage(error)}
                />
              )}
            />

            <Controller
              name="lastName"
              control={control}
              defaultValue=""
              render={({
                field: { ref, ...fieldProps },
                fieldState: { error },
              }) => (
                <Form.Input
                  {...fieldProps}
                  label={t('Last Name')}
                  placeholder={t('Last Name')}
                  type="lastName"
                  required
                  error={showErrorMessage(error)}
                />
              )}
            />
          </Form.Group>
        )}

        <Controller
          name="email"
          control={control}
          defaultValue=""
          render={({
            field: { ref, ...fieldProps },
            fieldState: { error },
          }) => (
            <Form.Input
              {...fieldProps}
              label={t('Email')}
              placeholder={t('Email')}
              type="email"
              required
              error={showErrorMessage(error)}
            />
          )}
        />

        <Controller
          name="password"
          control={control}
          defaultValue=""
          render={({
            field: { ref, ...fieldProps },
            fieldState: { error },
          }) => (
            <Form.Input
              {...fieldProps}
              label={t('Password')}
              placeholder={t('Password')}
              type="password"
              required
              error={showErrorMessage(error)}
            />
          )}
        />

        {/* <p>
          <Trans i18nKey="Terms of Use">
            By performing this action, you agree to our&nbsp;
            <Link onClick={onClose} to="/terms-of-use">
              Terms of Use
            </Link>
            &nbsp; and &nbsp;
            <Link onClick={onClose} to="/privacy-policy">
              Privacy Policy
            </Link>
            .
          </Trans>
        </p> */}

        <Form.Button primary fluid type="submit">
          {mode === 'signIn' ? 'Sign In' : 'Sign Up'}
        </Form.Button>

        {mode === 'signUp' && (
          <Segment textAlign="center" basic>
            {t('Already have an account?')}&nbsp;
            <Link to="/sign-in" onClick={onClose}>
              {t('Sign in')}
            </Link>
          </Segment>
        )}
      </Segment>
    </Form>
  );
};

export default SignInSignUpForm;
