import React from 'react';
import { Container, Grid, Segment } from 'semantic-ui-react';

const FooterNavigation = () => {
  return (
    <Segment
      vertical
      style={{
        marginTop: '3em',
        padding: '3em 0em',
        backgroundColor: 'rgb(241, 241, 241)',
      }}
    >
      <Container>
        <Grid stackable columns="equal">
          <Grid.Row>
            <Grid.Column textAlign="center">
              <img
                src="https://ucarecdn.com/c1303c54-e765-4c61-bdc0-3922e768c490/-/format/auto/"
                height="20"
                alt="Wonderschool Logo"
              />
            </Grid.Column>

            {/* <Grid.Column>
              <Header as="h5" content="Wonderschool" />
              <List link>
                <List.Item as="a">Our Vision</List.Item>
                <List.Item as="a">About us</List.Item>
                <List.Item as="a">Careers</List.Item>
                <List.Item as="a">Help</List.Item>
                <List.Item as="a">Press</List.Item>
                <List.Item as="a">For business</List.Item>
              </List>
            </Grid.Column>
            <Grid.Column>
              <Header as="h5" content="Follow us" />
              <List link>
                <List.Item as="a">Parent resources</List.Item>
                <List.Item as="a">Director resources</List.Item>
                <List.Item as="a">Company blog</List.Item>
                <List.Item as="a">Facebook</List.Item>
                <List.Item as="a">Instagram</List.Item>
                <List.Item as="a">Twitter</List.Item>
              </List>
            </Grid.Column>
            <Grid.Column>
              <Header as="h5" content="Contact us" />
              <List link>
                <List.Item as="a">Email us</List.Item>
              </List>
            </Grid.Column> */}
          </Grid.Row>
        </Grid>
      </Container>
    </Segment>
  );
};

export default FooterNavigation;
