import React from 'react';
import { useParams } from 'react-router-dom';
import Listings from '../components/Listings';
import ListingDetail from '../components/Listings/ListingDetail';

const ListingsPage = () => {
  const { id } = useParams();

  if (id) return <ListingDetail />;

  return <Listings />;
};

export default ListingsPage;
