import React from 'react';
import { Header, Grid, Segment } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

// Import hooks and styles.
import useAuth from '../../hooks/useAuth';

// Import coordinator components
import ApplicationsStat from '../Application/ApplicationsStat';
import AllApplicationList from '../Application/AllApplicationsList';

// Import parent components
import Application from '../Application';
import Favorites from '../Favorites';

const CoordinatorDashboard = ({ claims }) => {
  const { t } = useTranslation();
  if (claims && (claims?.coordinator || claims?.organizationAdmin))
    return (
      <Segment basic>
        <Grid columns="equal" stackable>
          <Grid.Row>
            <Grid.Column>
              <Header as="h1" content={t('Applications')} />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <ApplicationsStat />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              {/* <Header as="h2" content={'Applications'} /> */}

              <AllApplicationList />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    );

  return null;
};

const ParentDashboard = ({ displayName, claims }) => {
  const { t } = useTranslation();

  if (claims && (claims?.parent || claims?.teacher)) {
    return (
      <Segment basic>
        <Grid container columns="equal" stackable>
          <Grid.Row>
            <Grid.Column>
              <Header
                as="h1"
                content={
                  displayName
                    ? `${t('Hello')} ${displayName},`
                    : `${t('Hello')},`
                }
              />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <Application />
            </Grid.Column>

            <Grid.Column width={6}>
              <Favorites />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    );
  }

  return null;
};

const Dashboard = () => {
  const { user } = useAuth();
  const { displayName, claims } = user;

  return (
    <>
      <CoordinatorDashboard claims={claims} displayName={displayName} />
      <ParentDashboard claims={claims} displayName={displayName} />
    </>
  );
};

export default Dashboard;
