import algoliasearch from 'algoliasearch/lite';
import qs from 'qs';
import React, { createContext, useEffect, useState } from 'react';
import { InstantSearch, Configure } from 'react-instantsearch-dom';
import { useHistory, useLocation } from 'react-router-dom';

export const SearchContext = createContext();

const searchClient = algoliasearch(
  process.env.REACT_APP_ALGOLIA_APP_ID,
  process.env.REACT_APP_ALGOLIA_SEARCH_ONLY_API_KEY,
  {
    _useRequestCache: true,
  }
);

const updateAfter = 700;

export function SearchProvider({ children }) {
  const history = useHistory();
  const { pathname, search } = useLocation();

  const [searchState, setSearchState] = useState(qs.parse(search.slice(1)));

  useEffect(() => {
    window.addEventListener('popstate', ({ state: searchState }) => {
      setSearchState(searchState);
    });
  }, []);

  // Debounce using useEffect and setTimeout.
  useEffect(() => {
    const objectToURL = () => {
      return searchState ? `${pathname}?${qs.stringify(searchState)}` : '';
    };

    const timeOut = setTimeout(() => {
      const searchStateUrl = objectToURL(searchState);
      history.push(searchStateUrl, searchState);
    }, updateAfter);

    return () => {
      clearTimeout(timeOut);
    };
  }, [history, pathname, searchState]);

  const onSearchStateChange = (searchVal) => {
    setSearchState((prev) => {
      const hasQueryChanged = prev?.query !== searchVal?.query;
      return {
        ...prev,
        ...searchVal,
        boundingBox: !hasQueryChanged ? searchVal?.boundingBox : null,
      };
    });
  };

  const parameters = {};
  if (!searchState?.boundingBox) {
    parameters.aroundRadius = 'all';
    // parameters.aroundLatLngViaIP = true;
  }

  return (
    <InstantSearch
      searchClient={searchClient}
      searchState={searchState}
      onSearchStateChange={onSearchStateChange}
      indexName="listings"
    >
      <Configure {...parameters} />
      {children}
    </InstantSearch>
  );
}
