import React, { Component } from 'react';
import { Container, Segment } from 'semantic-ui-react';

// Import component
// import TopNavigation from '../Navigation/TopNavigation';
import MobileNavigation from '../Navigation/MobileNavigation';
// import FooterNavigation from '../Navigation/FooterNavigation';

class MobileContainer extends Component {
  state = { sidebarOpened: false };

  handlePusherClick = () => {
    const { sidebarOpened } = this.state;

    if (sidebarOpened) this.setState({ sidebarOpened: false });
  };

  handleToggle = () =>
    this.setState({ sidebarOpened: !this.state.sidebarOpened });

  render() {
    return (
      <>
        <Segment vertical className="navbar no-padding">
          <Container className="no-padding no-margin">
            <MobileNavigation handleToggle={this.handleToggle} />
          </Container>
        </Segment>

        <div id="content">{this.props.children}</div>
      </>
    );
  }
}

export default MobileContainer;
