import React from 'react';
import { Container } from 'semantic-ui-react';
import TopNavigation from './../Navigation/TopNavigation';

const DesktopContainer = ({ children }) => {
  return (
    <>
      <TopNavigation />

      <Container fluid>{children}</Container>
    </>
  );
};

export default DesktopContainer;
