import React from 'react';
import useAuth from '../hooks/useAuth';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const SignOutButton = () => {
  const { isAuthenticated } = useAuth();
  const { pathname } = useLocation();
  const { t } = useTranslation();

  if (!isAuthenticated && pathname !== '/dashboard') return null;

  return <Link to="/dashboard">{t('My Dashboard')}</Link>;
};

export default SignOutButton;
