import React from 'react';
import { Segment } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

const NotFoundPage = () => {
  const { t } = useTranslation();

  return (
    <Segment basic>
      <h1>{t('Unknown Path - 404')}</h1>
    </Segment>
  );
};

export default NotFoundPage;
