import {
  collection,
  deleteDoc,
  deleteField,
  doc,
  getDoc,
  onSnapshot,
  serverTimestamp,
  setDoc,
  updateDoc,
} from 'firebase/firestore';
import { get, isEmpty } from 'lodash-es';
import React, {
  createContext,
  useCallback,
  useEffect,
  useReducer,
} from 'react';
import { firestore } from '../api/firebase';
import useAuth from '../hooks/useAuth';
import { SearchProvider } from './SearchProvider';
export const ApplicationContext = createContext();

const steps = [
  'getting-started',
  'assistance',
  'children-information',
  'statistical-information',
];

const initialState = {
  applicationSubmitted: false,
  applicationData: {
    applicationId: null,
    gettingStarted: {},
    assistance: {},
    childrenInformation: {
      // Required to show first child form.
      children: [{ firstName: '', lastName: '', dateOfBirth: '' }],
    },
    statisticalInformation: {},
  },
  selectedListing: {},
  favorites: {},
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_APPLICATION_DATA': {
      return {
        ...state,
        applicationData: action.payload,
      };
    }
    case 'SET_APPLICATION_STEP_DATA':
      return {
        ...state,
        applicationData: {
          ...state.applicationData,
          [action.payload.step]: action.payload.data,
        },
      };
    case 'SET_SELECTED_LISTING':
      return {
        ...state,
        selectedListing: action.payload,
      };
    case 'SET_FAVORITES':
      return {
        ...state,
        favorites: action.payload,
      };
    case 'SET_APPLICATION_SUBMITTED':
      return {
        ...initialState,
        applicationSubmitted: action.payload,
      };
    default:
      return state;
  }
};

export function ApplicationProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { auth } = useAuth();

  // Set submission status.
  const setSubmissionState = useCallback((submitted) => {
    dispatch({
      type: 'SET_APPLICATION_SUBMITTED',
      payload: submitted,
    });
  }, []);

  // Start user draft data listener.
  useEffect(() => {
    if (!auth?.currentUser?.uid) return;
    const unsubscribe = onSnapshot(
      doc(
        firestore,
        `users/${auth.currentUser.uid}/drafts`,
        'coordinated-enrollment'
      ),
      (doc) => {
        dispatch({
          type: 'SET_APPLICATION_DATA',
          payload: { ...get(state, 'applicationData', {}), ...doc.data() },
        });
      }
    );

    return () => {
      unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth?.currentUser?.uid]);

  // Start user favorites data listener.
  useEffect(() => {
    if (!auth?.currentUser?.uid) return;
    const unsubscribe = onSnapshot(
      doc(
        firestore,
        `users/${auth.currentUser.uid}/favorites`,
        'coordinated-enrollment'
      ),
      (doc) => {
        dispatch({
          type: 'SET_FAVORITES',
          payload: doc.data() ?? {},
        });
      }
    );

    return () => {
      unsubscribe();
    };
  }, [auth?.currentUser?.uid]);

  // Add single step data
  const setApplicationStepData = useCallback(
    (step, data) => {
      // If current user is missing exit.
      if (!auth?.currentUser?.uid) return;

      // Save draft to users/{uid}/drafts/coordinated-enrollment.
      const draftRef = doc(
        firestore,
        `users/${auth.currentUser.uid}/drafts/coordinated-enrollment`
      );

      // Get application id if it exists.
      let applicationId = get(state, 'applicationData.applicationId', null);

      // If application id is missing create a new one.
      if (!applicationId)
        applicationId = doc(collection(firestore, 'applications')).id;

      return setDoc(
        draftRef,
        {
          [step]: data,
          applicationId,
          createdAt: get(state, 'applicationData.createdAt', serverTimestamp()),
          updatedAt: serverTimestamp(),
        },
        { merge: true }
      );
    },

    [auth?.currentUser?.uid, state]
  );

  // Add to favorites.
  const addToFavorites = useCallback(
    (listingId, favorite) => {
      // If current user is missing exit.
      if (!auth?.currentUser?.uid) return;

      // Save draft to users/{uid}/favorites/coordinated-enrollment.
      const favoriteRef = doc(
        firestore,
        `users/${auth.currentUser.uid}/favorites/coordinated-enrollment`
      );
      return setDoc(
        favoriteRef,
        {
          [listingId]: { ...favorite, createdAt: serverTimestamp() },
        },
        { merge: true }
      );
    },
    [auth?.currentUser?.uid]
  );

  // Delete favorite.
  const deleteFavorite = useCallback(
    (listingId) => {
      // If current user is missing exit.
      if (!auth?.currentUser?.uid) return;

      // Favorite ref.
      const favoriteRef = doc(
        firestore,
        `users/${auth.currentUser.uid}/favorites/coordinated-enrollment`
      );
      return updateDoc(favoriteRef, {
        [listingId]: deleteField(),
      });
    },
    [auth?.currentUser?.uid]
  );

  // Get listing data.
  const getListingData = useCallback(async (listingId) => {
    const listingRef = doc(firestore, `listings/${listingId}`);

    const docSnap = await getDoc(listingRef);

    if (docSnap.exists()) {
      dispatch({
        type: 'SET_SELECTED_LISTING',
        payload: docSnap.data(),
      });
    }
  }, []);

  // Set application data
  const setApplicationData = useCallback((data) => {
    dispatch({
      type: 'SET_APPLICATION_DATA',
      payload: data,
    });
  }, []);

  // Check if step form is marked done.
  const isStepDone = useCallback(
    (step) => get(state.applicationData, `${step}.done`, false),
    [state.applicationData]
  );

  // Set selected listing.
  const setSelectedListing = useCallback((listing = {}) => {
    dispatch({
      type: 'SET_SELECTED_LISTING',
      payload: listing,
    });
  }, []);

  // Submit application.
  const submitApplication = useCallback(async () => {
    // Exit if required fields objects are missing.
    if (
      !auth?.currentUser?.uid ||
      isEmpty(state?.applicationData) ||
      isEmpty(state?.favorites)
    )
      return;

    // Set application status.
    const organizationStatus = {};
    Object.keys(state.favorites).forEach((id) => {
      organizationStatus[id] = 'Applied';
    });

    // Get application id
    const id = get(state, 'applicationData.applicationId', null);

    if (!id) {
      console.log('no application id');
      return;
    }

    // Save application to applications collection.
    const applicationRef = doc(firestore, 'applications', id);

    // Save applications to applications/{applicationId}.
    await setDoc(applicationRef, {
      ...state.applicationData,
      uid: auth.currentUser.uid,
      organizationStatus: {
        ...organizationStatus,
        '6TFdgvml24HtAOyBtLoM': 'Applied',
      },
      status: 'submitted',
      region: Math.floor(Math.random() * 3) + 1,
      updatedAt: serverTimestamp(),
    });

    // Delete draft from users/{uid}/drafts/coordinated-enrollment.
    await deleteDoc(
      doc(
        firestore,
        `users/${auth.currentUser.uid}/drafts`,
        'coordinated-enrollment'
      )
    );

    // Set applicationSubmitted to true.
    setSubmissionState(true);
  }, [auth?.currentUser?.uid, state, setSubmissionState]);

  return (
    <ApplicationContext.Provider
      value={{
        ...state,
        steps,
        setApplicationStepData,
        setApplicationData,
        isStepDone,
        setSelectedListing,
        getListingData,
        addToFavorites,
        deleteFavorite,
        submitApplication,
        setSubmissionState,
      }}
    >
      <SearchProvider>{children}</SearchProvider>
    </ApplicationContext.Provider>
  );
}
