import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import MaskedInput from 'react-text-mask';
import { Button, Form, Header, Segment } from 'semantic-ui-react';
import * as yup from 'yup';
import 'yup-phone';
import { useTranslation } from 'react-i18next';

import useApplication from '../../../hooks/useApplication';
import { showErrorMessage } from '../../../utils/form';

const GettingStartedForm = () => {
  const history = useHistory();
  const { t } = useTranslation();

  const {
    applicationData: { gettingStarted = {} } = {},
    setApplicationStepData,
  } = useApplication();

  const schema = yup
    .object({
      firstName: yup.string().required(t('First name is required.')),
      lastName: yup.string().required(t('Last name is required.')),
      email: yup
        .string()
        .email(t('Invalid email address.'))
        .required(t('Email is required.')),

      phone: yup
        .string()
        .phone(1, true, t('Invalid phone number.')) // 1 = country code, true = strict, errorMessage
        .required(t('Phone number is required.')),
    })
    .required();

  const { handleSubmit, control, setValue } = useForm({
    resolver: yupResolver(schema),
  });

  // Load draft form values if any.
  React.useEffect(() => {
    Object.keys(gettingStarted).forEach((key) => {
      setValue(key, gettingStarted[key]);
    });
  }, [gettingStarted, setValue]);

  const onSubmit = (data) => {
    setValue('done', true);
    setApplicationStepData('gettingStarted', { ...data, done: true });
    history.push('/applications/new/assistance');
  };

  return (
    <Segment basic style={{ width: 650 }}>
      <Header as="h2">{t('Getting Started')}</Header>
      <Form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Controller
          name="firstName"
          control={control}
          defaultValue=""
          render={({
            field: { ref, ...fieldProps },
            fieldState: { error },
          }) => (
            <Form.Input
              {...fieldProps}
              label={t('Applicant First Name')}
              required
              error={showErrorMessage(error)}
            />
          )}
        />

        <Controller
          name="middleName"
          control={control}
          defaultValue=""
          render={({ field: { ref, ...fieldProps } }) => (
            <Form.Input {...fieldProps} label={t('Applicant Middle Name')} />
          )}
        />

        <Controller
          name="lastName"
          control={control}
          defaultValue=""
          render={({
            field: { ref, ...fieldProps },
            fieldState: { error },
          }) => (
            <Form.Input
              {...fieldProps}
              label={t('Applicant Last Name')}
              required
              error={showErrorMessage(error)}
            />
          )}
        />

        <Controller
          name="suffix"
          control={control}
          defaultValue=""
          render={({ field: { ref, ...fieldProps } }) => (
            <Form.Input
              {...fieldProps}
              label={t('Applicant Suffix (Examples: Jr, Sr. III, IV, etc...)')}
            />
          )}
        />

        <Controller
          name="email"
          control={control}
          defaultValue=""
          render={({
            field: { ref, ...fieldProps },
            fieldState: { error },
          }) => (
            <Form.Input
              {...fieldProps}
              label={t('Applicant Email Address')}
              type="email"
              required
              error={showErrorMessage(error)}
            />
          )}
        />

        <Controller
          name="phone"
          control={control}
          defaultValue=""
          render={({
            field: { ref, ...fieldProps },
            fieldState: { error },
          }) => (
            <Form.Input
              {...fieldProps}
              label={t('Applicant Phone Number')}
              guide={false}
              required
              control={MaskedInput}
              mask={[
                '(',
                /[1-9]/,
                /\d/,
                /\d/,
                ')',
                ' ',
                /\d/,
                /\d/,
                /\d/,
                '-',
                /\d/,
                /\d/,
                /\d/,
                /\d/,
              ]}
              error={showErrorMessage(error)}
            />
          )}
        />

        <Button primary fluid>
          {'Continue'}
        </Button>
      </Form>
    </Segment>
  );
};

export default GettingStartedForm;
