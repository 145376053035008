import React from 'react';
import { useTranslation } from 'react-i18next';

import useAuth from '../hooks/useAuth';
import { Link } from 'react-router-dom';

const SignOutButton = () => {
  const { isAuthenticated, signOut } = useAuth();
  const { t } = useTranslation();

  if (!isAuthenticated) return null;

  return (
    <Link to="/" onClick={signOut}>
      {t('Sign Out')}
    </Link>
  );
};

export default SignOutButton;
