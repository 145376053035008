import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import '../src/api/firebase';
import App from './App';

// // import i18n (needs to be bundled ;))
import './i18n';

// Import styles.
import './styles/css/bootstrap-iso.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'instantsearch.css/themes/algolia.css';
import './styles/index.scss';

import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <>
    <Suspense fallback="Loading...">
      <App />
    </Suspense>
  </>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
