import React from 'react';

// Import component.
import Dashboard from '../components/Dashboard';

const DashboardPage = () => {
  return <Dashboard />;
};

export default DashboardPage;
