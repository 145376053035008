import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { Button, Form, Header, Segment } from 'semantic-ui-react';
import * as yup from 'yup';
import 'yup-phone';
import { useTranslation } from 'react-i18next';

import useApplication from '../../../hooks/useApplication';
import { showErrorMessage } from '../../../utils/form';
import CheckboxList from '../../CheckboxList';
import FormYesNoRadio from '../../FormYesNoRadio';

const StatisticalInformationForm = () => {
  const history = useHistory();
  const { t } = useTranslation();

  const {
    applicationData: { statisticalInformation = {} } = {},
    setApplicationStepData,
  } = useApplication();

  const schema = yup
    .object({
      singleParent: yup.string().nullable().default(null),
      financialAssistanceStatus: yup
        .array()
        .min(1, t('Financial assistance status is required.'))
        .required(t('Financial assistance status is required.')),
      financialAssistanceStatusNote: yup
        .string()
        .when('financialAssistanceStatus', {
          is: (val) =>
            val?.includes(t('Other (please specify in notes below)')),
          then: yup.string().required(t('Please specify notes below.')),
        }),
    })
    .required();

  const { handleSubmit, control, setValue } = useForm({
    resolver: yupResolver(schema),
  });

  // Load draft form values if any.
  React.useEffect(() => {
    Object.keys(statisticalInformation).forEach((key) => {
      setValue(key, statisticalInformation[key]);
    });
  }, [statisticalInformation, setValue]);

  const onSubmit = (data) => {
    setValue('done', true);
    setApplicationStepData('statisticalInformation', { ...data, done: true });
    history.push('/select-providers');
  };

  return (
    <Segment basic style={{ width: 650 }}>
      <Header as="h2">{t('Statistical Information')}</Header>
      <Form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Controller
          name="financialAssistanceStatus"
          control={control}
          render={({
            field: { ref, ...fieldProps },
            fieldState: { error },
          }) => (
            <CheckboxList
              {...fieldProps}
              required
              options={[
                t('I am currently receiving Child Care Subsidy'),
                t('I am applying for/will apply for child care subsidy'),
                t(
                  'I would like more information about how to apply for child care subsidy'
                ),
                t(
                  'I am currently receiving Child Care Aware of America military fee assistance'
                ),
                t('I am applying for/will apply for military fee assistance'),
                t(
                  'I would like more information about how to apply for military fee assistance'
                ),
                t(
                  'I am ineligible/not interested in financial assistance options'
                ),
                t('Other (please specify in notes below)'),
              ]}
              label={t('My financial assistance status is...')}
              error={showErrorMessage(error)}
            />
          )}
        />

        <Controller
          name="financialAssistanceStatusNote"
          control={control}
          defaultValue=""
          render={({
            field: { ref, ...fieldProps },
            fieldState: { error },
          }) => (
            <Form.Input
              {...fieldProps}
              label={t('Notes')}
              error={showErrorMessage(error)}
            />
          )}
        />

        <Controller
          name="singleParent"
          control={control}
          render={({
            field: { ref, ...fieldProps },
            fieldState: { error },
          }) => (
            <FormYesNoRadio
              {...fieldProps}
              label={t('Are you a single parent?')}
              error={showErrorMessage(error)}
              onChange={(vale) => {
                fieldProps.onChange(vale);
              }}
            />
          )}
        />

        <Controller
          name="occupation"
          control={control}
          defaultValue=""
          render={({
            field: { ref, ...fieldProps },
            fieldState: { error },
          }) => (
            <Form.Input
              {...fieldProps}
              label={t('My job is...')}
              error={showErrorMessage(error)}
            />
          )}
        />

        <Controller
          name="additionalInformation"
          control={control}
          defaultValue=""
          render={({
            field: { ref, ...fieldProps },
            fieldState: { error },
          }) => (
            <Form.TextArea
              {...fieldProps}
              label={t(
                'Please enter any additional information that may help us conduct your search. Such as any special needs, medication that needs to be given while in care, etc.'
              )}
              error={showErrorMessage(error)}
            />
          )}
        />

        <Button primary fluid>
          {t('Continue')}
        </Button>
      </Form>
    </Segment>
  );
};

export default StatisticalInformationForm;
