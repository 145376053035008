import React, { useState } from 'react';
import { Button, Modal } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import useAuth from '../hooks/useAuth';
import SignInSignUpForm from './Forms/SignInSignUpForm';

const SignInButton = () => {
  const [open, setOpen] = useState(false);
  const { isAuthenticated } = useAuth();
  const { t } = useTranslation();

  if (isAuthenticated) return null;

  return (
    <>
      <Button basic color="blue" onClick={() => setOpen(true)}>
        {t('Sign In')}
      </Button>

      <Modal
        open={open}
        header={t('Welcome back')}
        closeIcon
        closeOnDimmerClick={false}
        closeOnEscape={false}
        content={
          <SignInSignUpForm mode="signIn" onClose={() => setOpen(false)} />
        }
        size="tiny"
        onClose={() => setOpen(false)}
      />
    </>
  );
};

export default SignInButton;
