import React from 'react';
import { Grid, Segment } from 'semantic-ui-react';

// Import utils.
import { useWindowDimensions } from '../../utils/windowDimentions';
import ListingsHits from './ListingsHits';
import ListingsMap from './ListingsMap';

// Import components.
import SearchFacets from './SearchFacets';
import ContentContainer from '../Layout/ContentContainer';

const Listings = () => {
  const { height } = useWindowDimensions();

  return (
    <>
      {/* <SearchFacets /> */}

      <Grid stackable columns={2}>
        <Grid.Column
          width={11}
          className="no-top-padding"
          style={{
            maxHeight: height - 131,
            overflowX: 'scroll',
            paddingRight: 0,
          }}
        >
          <ContentContainer>
            {/* <ListingStat /> */}

            <Segment basic>
              <ListingsHits />
            </Segment>
          </ContentContainer>
        </Grid.Column>

        <Grid.Column width={5} className="no-padding">
          <ListingsMap height={height - 131} />
        </Grid.Column>
      </Grid>
    </>
  );
};

export default Listings;
