import { getAnalytics } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { getAuth, connectAuthEmulator } from 'firebase/auth';
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore';
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';
import { getStorage, connectStorageEmulator } from 'firebase/storage';

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyAuuCN8lxEJERlc-UUd5b__S67jMnq6xc4',
  authDomain: 'moxit-wonderschool-dev.firebaseapp.com',
  projectId: 'moxit-wonderschool-dev',
  storageBucket: 'moxit-wonderschool-dev.appspot.com',
  messagingSenderId: '295197937196',
  appId: '1:295197937196:web:8a13ed02824311493c8b89',
  measurementId: 'G-2PLR909Q68',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const analytics = getAnalytics(app);
const firestore = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app);
const functions = getFunctions(app);

/**
 * To use firebase emulator
 * start firebase emulator: firebase emulators:start
 * start react app: yarn start
 */
if (
  process.env.NODE_ENV === 'development' &&
  process.env.REACT_APP_FIREBASE_EMULATOR === 'ACTIVE'
) {
  connectAuthEmulator(auth, 'http://localhost:9099');
  connectFirestoreEmulator(firestore, 'localhost', 8080);
  connectFunctionsEmulator(functions, 'localhost', 5001);
  connectStorageEmulator(storage, 'localhost', 9199);
}

export { auth, firestore, analytics, functions, storage };
export default app;
