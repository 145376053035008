import React, { useEffect, useState } from 'react';
import {
  collection,
  limit,
  onSnapshot,
  orderBy,
  query,
} from '@firebase/firestore';
import { isEmpty, find, upperFirst } from 'lodash-es';
import { Card, Label, Button, Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { firestore } from '../../api/firebase';
import useAuth from '../../hooks/useAuth';
import { firebaseServerTimeToDate } from '../../utils/dates';

const LABEL_COLOR = {
  draft: 'purple',
  submitted: 'orange',
  'in-process': 'yellow',
  approved: 'green',
  rejected: 'red',
};

const MyApplicationList = () => {
  const { auth } = useAuth();
  const [applicationList, setApplicationList] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    if (!auth?.currentUser?.uid) return;

    const q = query(
      collection(firestore, `users/${auth.currentUser.uid}/applications`),
      orderBy('createdAt', 'desc'),
      limit(20) // Limiting it to 20 to save real estate
    );

    // Application collection listener
    const unsubscribe = onSnapshot(q, (snapshot) => {
      if (!snapshot.empty)
        setApplicationList(
          snapshot.docs.map((doc) => {
            const {
              gettingStarted: { firstName, lastName },
            } = doc.data();

            const applicationName = `${firstName} ${lastName}'s ${t('Family')}`;
            return {
              id: doc.id,
              applicationName,
              priorityRank: 2,
              ...doc.data(),
            };
          })
        );
    });

    return () => {
      unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  const newApplication = () => {
    const isDraftApplication = find(applicationList, { status: 'draft' });

    return (
      isEmpty(isDraftApplication) && (
        <Card as={Link} to={'/applications/new'} className="add-new" fluid>
          <Card.Content>
            <div className="card-content">
              <Card.Header as={'h3'}>
                <Icon name="plus" />
              </Card.Header>
              <Card.Description>
                {t('Start a new application')}
              </Card.Description>
            </div>
          </Card.Content>
        </Card>
      )
    );
  };

  return (
    <Card.Group itemsPerRow={1}>
      {!isEmpty(applicationList) && (
        <>
          {applicationList.map(
            ({
              id,
              createdAt,
              updatedAt,
              gettingStarted: { firstName = '', lastName = '', email = '' },
              status,
            }) => {
              return (
                <Card key={id} fluid>
                  <Card.Content>
                    <Label color={LABEL_COLOR[status]} attached="top">
                      {upperFirst(status)}
                    </Label>
                    <Card.Header
                      content={`${t(
                        'Application form'
                      )} ${firstName} ${lastName}'s ${t('Family')}`}
                    />
                    <Card.Meta>
                      <div className="date">{`${t(
                        'Started on'
                      )}: ${firebaseServerTimeToDate(
                        createdAt,
                        'MMMM Do YYYY - h:mm A'
                      )}`}</div>
                      <div className="date">{` ${
                        status === 'draft'
                          ? t('Modified on')
                          : t('Submitted on')
                      }: ${firebaseServerTimeToDate(
                        updatedAt,
                        'MMMM Do YYYY - h:mm A'
                      )}`}</div>
                    </Card.Meta>
                    <Card.Description></Card.Description>
                  </Card.Content>
                  {status === 'draft' ? (
                    <Card.Content extra textAlign="right">
                      <Button basic content={t('Widthdraw application')} />
                      <Button
                        primary
                        content={t('Continue application')}
                        as={Link}
                        to={'/applications/new'}
                      />
                    </Card.Content>
                  ) : (
                    <Card.Content extra textAlign="right">
                      <Button
                        primary
                        basic
                        content={t('View my application')}
                        as={Link}
                        to={`/applications/${id}`}
                      />
                    </Card.Content>
                  )}
                </Card>
              );
            }
          )}
        </>
      )}

      {newApplication()}
    </Card.Group>
  );
};

export default MyApplicationList;
