import {
  collection,
  doc,
  limit,
  onSnapshot,
  query,
  updateDoc,
} from '@firebase/firestore';
import { isEmpty } from 'lodash-es';
import React, { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { connectInfiniteHits, Highlight } from 'react-instantsearch-dom';
import {
  Button,
  Card,
  Header,
  Icon,
  Image,
  Modal,
  Segment,
} from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

// Import hooks
import { firestore } from '../../api/firebase';
import useAuth from '../../hooks/useAuth';
// Import required components
import SectionCard from '../SectionCard';

const ListingCard = ({ id, item, matched, onClick }) => {
  const { t } = useTranslation();

  if (isEmpty(item)) return null;

  const matchedStyle = matched
    ? { border: '1px #2e8bd2 solid !important' }
    : {};

  const meta = (
    <span>
      <span>{t('$200 - $1800/mo')}</span>
      <span>{`${item?.hoursStart}:00am-${item?.hoursStart}:00pm`}</span>
      <div>{t('Family Child Care')}</div>
      <div>{`${t('Available spots')}: ${
        Math.floor(Math.random() * 8) + 1
      }`}</div>
    </span>
  );

  return (
    <Card
      key={id}
      fluid
      className={matched ? `` : `simple`}
      style={matchedStyle}
    >
      <Image src={item?.headerImageUrl} wrapped ui={false} />
      <Card.Content style={{ paddingLeft: 0, paddingRight: 0 }}>
        <Card.Header as={Link} to={`/listings/${id}`}>
          <Image
            src="https://ucarecdn.com/4bbbdf2f-f26a-4026-b210-c3fbd2d59b77/-/scale_crop/13x15/"
            style={{ width: '13px' }}
          />{' '}
          {item?.name}
        </Card.Header>

        <Card.Meta content={meta} style={{ fontSize: '14px' }} />

        {/* <Card.Description>
          <Rating color="yellow" rating={5} maxRating={5} />
        </Card.Description> */}
      </Card.Content>

      <Card.Content
        style={{ padding: 0, borderTop: '0px #fff solid' }}
        textAlign="right"
      >
        <Button
          primary
          size="small"
          style={{ padding: '0.5em 1em' }}
          onClick={(e) => {
            e.stopPropagation();
            onClick(e);
          }}
        >
          Match
        </Button>
      </Card.Content>
    </Card>
  );
};

const MatchingList = ({ hits }) => {
  const [listings, setListings] = useState([]);
  const [matched, setMatched] = useState({});
  const [open, setOpen] = useState(false);
  const [secondOpen, setSecondOpen] = useState(false);
  const { id: applicationId } = useParams();

  const { auth } = useAuth();
  const history = useHistory();
  const { t } = useTranslation();

  useEffect(() => {
    if (!auth?.currentUser?.uid) return;

    setListings(hits);
    // const q = query(
    //   collection(firestore, `listings`),
    //   // orderBy('createdAt', 'desc'),
    //   limit(10) // Limiting it to 20 to save real estate
    // );

    // // Application collection listener
    // const unsubscribe = onSnapshot(q, (snapshot) => {
    //   if (!snapshot.empty)
    //     setListings(
    //       snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
    //     );
    // });

    // return () => {
    //   unsubscribe();
    // };
  }, [auth]);

  return (
    <SectionCard header={t('Matching Listings')}>
      <div style={{ maxHeight: '1100px', overflowY: 'scroll' }}>
        <Card.Group stackable itemsPerRow={1}>
          {listings?.map((item, key) => {
            const { id } = item;

            const isMatched = matched[id] || false;

            return (
              <ListingCard
                key={key}
                id={key}
                item={item}
                matched={isMatched}
                onClick={() => {
                  setMatched({ ...matched, [id]: true });
                }}
              />
            );
          })}
        </Card.Group>
      </div>

      <Segment basic>
        <Modal
          size="tiny"
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
          open={open}
          trigger={
            <Button
              primary
              fluid
              content={t(`Notify Matched Programs`)}
              disabled={isEmpty(matched) ? true : false}
            />
          }
        >
          <Modal.Header>{t('Are you sure?')}</Modal.Header>
          <Modal.Content>
            <Modal.Description>
              {t('Would you like to notify the matched programs?')}
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={() => setOpen(false)}>{t('Cancel')}</Button>
            <Button
              content="Yes, Notify"
              onClick={async () => {
                if (!applicationId) return;
                // Update firestore document
                const applicationRef = doc(
                  firestore,
                  'applications',
                  applicationId
                );

                await updateDoc(applicationRef, {
                  status: 'in-progress',
                });

                setSecondOpen(true);
              }}
              primary
            />
          </Modal.Actions>
        </Modal>

        <Modal
          onClose={() => setSecondOpen(false)}
          open={secondOpen}
          size="small"
        >
          <Modal.Header>{t('Confirmation')}</Modal.Header>
          <Modal.Content>
            <Segment basic textAlign="center">
              <Header as="h2" icon>
                <Icon name="check circle" color="green" />
                {t('Notification is sent.')}
                <Header.Subheader>
                  {t(
                    'The matched programs will be notified via email and SMS.'
                  )}
                </Header.Subheader>
              </Header>
            </Segment>
          </Modal.Content>
          <Modal.Actions>
            <Button
              icon="check"
              content={t('All Done')}
              onClick={() => {
                setSecondOpen(false);
                setOpen(false);
                history.push('/dashboard');
              }}
            />
          </Modal.Actions>
        </Modal>
      </Segment>
    </SectionCard>
  );
};

export default connectInfiniteHits(MatchingList);
