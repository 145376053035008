import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Modal } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import useAuth from '../hooks/useAuth';
import SignInSignUpForm from './Forms/SignInSignUpForm';

const ApplyButton = () => {
  const [open, setOpen] = useState(false);
  const { isAuthenticated } = useAuth();
  const history = useHistory();
  const { t } = useTranslation();

  const handleOnClick = () => {
    if (isAuthenticated) {
      history.push('/dashboard');
    } else {
      setOpen(true);
    }
  };

  if (isAuthenticated) return null;

  return (
    <>
      <Button primary onClick={handleOnClick}>
        {t('Apply')}
      </Button>

      <Modal
        open={open}
        header={t('Create a parent account, it’s free!')}
        content={
          <SignInSignUpForm mode="signUp" onClose={() => setOpen(false)} />
        }
        closeIcon
        closeOnDimmerClick={false}
        closeOnEscape={false}
        onClose={() => setOpen(false)}
        size="tiny"
      />
    </>
  );
};

export default ApplyButton;
