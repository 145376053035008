import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { Menu, Icon, Image, Modal } from 'semantic-ui-react';

// // Import helpers.
// import { getCurrentUser } from '../../api/firebase/account';

// // Import Logo
// import wsLogo from '../../styles/img/wonderschool/logo-base.png';
import partnerLogo from '../../styles/img/patner/vecf-logo.png';
import partnerWideLogo from '../../styles/img/patner/vecf-wide-logo.jpg';

// Import components.
import TopNavigation from './TopNavigation';

const MobileNavigation = ({ auth, user, permissions, handleToggle }) => {
  const [menuOpen, setMenuOpen] = useState(false);

  // if (!getCurrentUser()) return null;

  const renderMobileHamburger = () => {
    return (
      <Modal
        closeIcon
        centered={false}
        open={menuOpen}
        onClose={() => setMenuOpen(false)}
        onOpen={() => setMenuOpen(true)}
        trigger={
          <Menu.Item className="hamburger">
            <Icon name="sidebar" />
          </Menu.Item>
        }
      >
        <Modal.Header>
          <Image
            // src={partnerLogo}
            src={'https://cdec.colorado.gov/sites/cdec/files/logo.svg'}
            style={{
              height: '100px',
              paddingTop: '0.5em',
              paddingBottom: '0.5em',
            }}
          />
        </Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <TopNavigation
              viewport="mobile"
              handleShowMenu={() => setMenuOpen(false)}
            />
          </Modal.Description>
        </Modal.Content>
      </Modal>
    );
  };

  return (
    <Menu secondary size="large">
      {renderMobileHamburger()}
      <Menu.Item>
        <Image as={Link} to="/" src={partnerWideLogo} size="medium" />
      </Menu.Item>
    </Menu>
  );
};

export default withRouter(MobileNavigation);
