import React, { useEffect } from 'react';
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import { Button, Container, Header, Segment } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import checkMark from '../assets/check-mark.svg';
import useApplication from '../hooks/useApplication';

const ConfirmationPage = () => {
  const { setSubmissionState, applicationSubmitted } = useApplication();
  const { t } = useTranslation();

  // On unmount, clear the submission state
  useEffect(() => {
    return () => {
      setSubmissionState(false);
    };
  }, [setSubmissionState]);

  if (!applicationSubmitted) return <Redirect to="/applications/new" />;

  return (
    <Container textAlign="center">
      <Segment basic textAlign="center">
        <img src={checkMark} alt="React Logo" />
        <Header as="h1">{t('Application Submitted')}</Header>
        <p>
          {t(
            "You're all set. you'll receive an email and text message when your application is matched by our team."
          )}
        </p>

        <Button fluid primary as={Link} to="/dashboard">
          {t('Continue')}
        </Button>
      </Segment>
    </Container>
  );
};

export default ConfirmationPage;
