import React, { useEffect, useState } from 'react';
import { doc, getDoc } from '@firebase/firestore';
import { Breadcrumb, Grid, Card, Header, Label } from 'semantic-ui-react';
import { Link, useParams } from 'react-router-dom';
import { upperFirst } from 'lodash-es';
import { useTranslation } from 'react-i18next';

// Import api, hooks and utils.
import { firestore } from '../../api/firebase';
import useAuth from '../../hooks/useAuth';
import { firebaseServerTimeToDate } from '../../utils/dates';

// Import components.
import SectionCard from '../SectionCard';
import ContentContainer from '../Layout/ContentContainer';
import MatchingList from '../Listings/MatchingList';

const LABEL_COLOR = {
  draft: 'purple',
  submitted: 'orange',
  'in-process': 'yellow',
  approved: 'green',
  rejected: 'red',
};

const LABEL_YESNO = {
  yes: 'Yes',
  no: 'No',
};

const ApplicationDetail = () => {
  const { auth } = useAuth();
  const { id } = useParams();
  const { t } = useTranslation();

  const [application, setApplication] = useState({});

  useEffect(() => {
    if (!auth?.currentUser?.uid || !id) return;

    const getData = async () => {
      try {
        const applicationRef = doc(firestore, `applications/${id}`);

        const docSnap = await getDoc(applicationRef);

        if (docSnap.exists()) {
          const {
            gettingStarted: { firstName, lastName },
          } = docSnap.data();

          const applicationName = `${firstName} ${lastName}'s Family Application`;

          setApplication({
            id: docSnap.id,
            applicationName,
            priorityRank: 2,
            ...docSnap.data(),
          });
        }
      } catch (error) {
        console.error(error);
      }
    };

    getData();

    return () => {};
  }, [auth, id]);

  const GettingStarted = ({ gettingStarted }) => {
    return (
      <Grid stackable columns={2}>
        <FormRow label="Full Name">{renderFullName(gettingStarted)}</FormRow>
        <FormRow label="Email">{gettingStarted?.email}</FormRow>
        <FormRow label="Phone Number">{gettingStarted?.phone}</FormRow>
      </Grid>
    );
  };

  const ChildrenInformation = ({ childrenInformation }) => {
    return (
      <Grid stackable columns={2}>
        <FormRow label="Desired Care Center">
          {childrenInformation?.careCenterType?.map((ccType, index) => (
            <div key={index}>{ccType}</div>
          ))}
        </FormRow>

        <FormRow label="Days Care Needed">
          {childrenInformation?.daysCareNeeded?.map((day, index) => (
            <div key={index}>{day}</div>
          ))}
        </FormRow>

        <FormRow label="Dropoff Time">
          {childrenInformation?.dropOffTimeNeeded ||
            'No dropoff information added'}
        </FormRow>

        <FormRow label="Pickup Time">
          {childrenInformation?.pickupTimeNeeded ||
            'No pickup time information added'}
        </FormRow>

        <FormRow label="Children">
          {childrenInformation?.children?.map((child, index) => {
            return (
              <Card fluid key={index}>
                <Card.Content>
                  <Card.Header>{renderFullName(child)}</Card.Header>
                  <Card.Meta>{child?.dateOfBirth}</Card.Meta>
                  <Card.Description>
                    <FormRow label="Has special needs">
                      {LABEL_YESNO[child.specialNeeds]}
                    </FormRow>
                  </Card.Description>
                </Card.Content>
              </Card>
            );
          })}
        </FormRow>
      </Grid>
    );
  };

  const Assistance = ({ assistance }) => {
    return (
      <Grid stackable columns={2}>
        <FormRow
          label="Needs Financial Assistance"
          value={LABEL_YESNO[assistance?.needAssistance]}
        />
        <FormRow
          label="Receives Grants"
          value={LABEL_YESNO[assistance?.receiveGrants]}
        />
      </Grid>
    );
  };

  const StatisticalInformation = ({ statisticalInformation }) => {
    const note = statisticalInformation?.financialAssistanceStatusNote;
    return (
      <Grid stackable columns={2}>
        <FormRow label="Occupation">
          {LABEL_YESNO[statisticalInformation?.occupation] ||
            'No occupation information added'}
        </FormRow>

        <FormRow
          label="Single Parent"
          value={
            LABEL_YESNO[statisticalInformation?.singleParent] ||
            'No single parent information added'
          }
        />

        <FormRow label="Assistance Status">
          {statisticalInformation?.financialAssistanceStatus?.map(
            (status, index) => (
              <div key={index}>{status}</div>
            )
          )}
          {note && <div>{note}</div>}
        </FormRow>

        <FormRow label="Additional Information">
          {statisticalInformation?.additionalInformation ||
            'No additional information added'}
        </FormRow>
      </Grid>
    );
  };

  const FormRow = ({ label, value, children }) => {
    return (
      <Grid.Row>
        <Grid.Column width={6}>{t(label)}</Grid.Column>
        <Grid.Column width={10}>{value || children}</Grid.Column>
      </Grid.Row>
    );
  };

  const renderFullName = (person) => {
    return (
      (person?.suffix ? ' ' + person?.suffix : '') +
      person?.firstName +
      ' ' +
      (person?.middleName ? person?.middleName + ' ' : '') +
      person?.lastName
    );
  };

  const renderBreadcrumb = (gettingStarted) => (
    <Breadcrumb>
      <Breadcrumb.Section link as={Link} to="/dashboard">
        {t('My Dashboard')}
      </Breadcrumb.Section>
      <Breadcrumb.Divider />
      <Breadcrumb.Section active>
        {application?.applicationName}
      </Breadcrumb.Section>
    </Breadcrumb>
  );

  return (
    <ContentContainer>
      <Grid container stackable columns={2}>
        <Grid.Row>
          <Grid.Column width={16}>
            {renderBreadcrumb(application?.gettingStarted)}
          </Grid.Column>
        </Grid.Row>

        <Grid.Row verticalAlign="middle">
          <Grid.Column width={13}>
            <Header as="h1">
              <Header.Content>
                {application?.applicationName}
                <Header.Subheader>
                  {application?.submittedAt
                    ? firebaseServerTimeToDate(
                        application?.submittedAt,
                        'MMMM Do YYYY - h:mm A'
                      )
                    : firebaseServerTimeToDate(
                        application?.createdAt,
                        'MMMM Do YYYY - h:mm A'
                      )}
                </Header.Subheader>
              </Header.Content>
            </Header>
          </Grid.Column>

          <Grid.Column width={3} textAlign="right">
            {/* <Button primary content="Edit" fluid /> */}
            <Label color={LABEL_COLOR[application?.status]} size="huge" fluid>
              {upperFirst(application?.status)}
            </Label>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={11}>
            <SectionCard header={t('Getting Started')}>
              <GettingStarted gettingStarted={application?.gettingStarted} />
            </SectionCard>

            <SectionCard header={t('Financial Assistance')}>
              <Assistance assistance={application?.assistance} />
            </SectionCard>

            <SectionCard header={t('Children Information')}>
              <ChildrenInformation
                childrenInformation={application?.childrenInformation}
              />
            </SectionCard>

            <SectionCard header={t('Statistical Information')}>
              <StatisticalInformation
                statisticalInformation={application?.statisticalInformation}
              />
            </SectionCard>
          </Grid.Column>

          <Grid.Column width={5}>
            {application?.status === 'submitted' && <MatchingList />}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </ContentContainer>
  );
};

export default ApplicationDetail;
