import { kebabCase } from 'lodash-es';
import React from 'react';
import { Form } from 'semantic-ui-react';

const CheckboxList = ({
  label = '',
  options = [],
  value = [],
  required = false,
  error,
  onChange = () => null,
}) => {
  // Toggle array of days of the week.
  const toggleDaysOfWeek = (day) => {
    const index = value.indexOf(day);
    if (index === -1) {
      return [...value, day];
    }
    return [...value.slice(0, index), ...value.slice(index + 1)];
  };

  return (
    <>
      <Form.Field label={label} required={required} error={error} />

      {options.map((day, index) => (
        <Form.Checkbox
          key={kebabCase(day)}
          id={kebabCase(day)}
          label={day}
          checked={value.includes(day)}
          onChange={() => onChange(toggleDaysOfWeek(day))}
        />
      ))}
    </>
  );
};

export default CheckboxList;
