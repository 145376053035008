import React from 'react';
import { Menu } from 'semantic-ui-react';
import useAuth from '../../hooks/useAuth';

const NavigationItem = ({ as, to, children, authOnly = false }) => {
  const { isAuthenticated } = useAuth();

  const navOptions = {};

  if (as) {
    navOptions.as = as;
  }

  if (to) {
    navOptions.to = to;
  }

  if (authOnly && !isAuthenticated) {
    return null;
  }

  return <Menu.Item {...navOptions}>{children}</Menu.Item>;
};

export default NavigationItem;
