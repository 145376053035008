import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import {
  Button,
  Container,
  Divider,
  Grid,
  Header,
  Icon,
  Image,
  Segment,
} from 'semantic-ui-react';
import { isEmpty, find } from 'lodash-es';
import { useTranslation } from 'react-i18next';

// Import hooks & style.
import useApplication from '../../hooks/useApplication';
import './styles/listing-detail.scss';

// Import components.
import ApplyToEnrollButton from '../ApplyToEnrollButton';
import ContentContainer from '../Layout/ContentContainer';

const ListingRow = ({ label, value }) => {
  return (
    <Grid.Column>
      <div style={{ fontWeight: 'bold' }}>{label}</div>
      {value}
    </Grid.Column>
  );
};

const ListingDetail = () => {
  const { id } = useParams();
  const { t } = useTranslation();

  const {
    favorites,
    getListingData,
    selectedListing: selected,
    addToFavorites,
    deleteFavorite,
  } = useApplication();

  useEffect(() => {
    if (!id) return;
    if (isEmpty(selected)) getListingData(id);
  }, [getListingData, id, selected]);

  const renderAddToFavorite = () => {
    const isFavorite = favorites && find(favorites, (f, key) => key === id);

    if (isFavorite) {
      return (
        <Button
          basic
          primary
          fluid
          size="large"
          className="action-button"
          icon
          labelPosition="left"
          onClick={() => {
            deleteFavorite(id);
            // addToFavorites(id, selected);
            // history.push('/favorites');
          }}
        >
          <Icon name="heart" color="red" />
          {t('Add to favorites')}
        </Button>
      );
    } else {
      return (
        <Button
          primary
          fluid
          size="large"
          className="action-button"
          icon
          labelPosition="left"
          onClick={() => {
            addToFavorites(id, selected);
            // history.push('/favorites');
          }}
        >
          <Icon name="heart outline" />
          {t('Add to favorites')}
        </Button>
      );
    }
  };

  return (
    <ContentContainer>
      <div className="listing-detail">
        <Segment
          basic
          placeholder
          className="hero"
          style={{
            backgroundSize: '100%',
            background: `linear-gradient(rgba(40, 50, 90, 0.6), rgba(40, 50, 90, 0.6)), url(${selected?.headerImageUrl}) no-repeat center center`,
            height: '340px',
            paddingBottom: '7em',
            paddingTop: '7em',
          }}
          textAlign="center"
        >
          <Header className="detail-title" as="h1">
            {selected?.name}
          </Header>
        </Segment>

        <Container>
          <Segment basic className="section-one">
            <Grid stackable columns={2}>
              <Grid.Column width={10}>
                <Grid.Row textAlign="center">
                  <Image
                    src={selected?.directorAvatarUrl}
                    size="small"
                    circular
                    style={{
                      border: '6px solid #fff',
                    }}
                  />
                  <div style={{ marginLeft: '15px' }}>
                    <Header
                      as="h3"
                      style={{ marginTop: '5px', marginBottom: '5px' }}
                    >
                      {selected?.directorFirstName} {selected?.directorLastName}
                    </Header>
                    <Header.Subheader className="meta">
                      {t('Program director')}
                    </Header.Subheader>
                  </div>
                </Grid.Row>

                <Grid.Row>
                  <Divider />
                </Grid.Row>

                <Grid.Row>
                  <Grid columns="equal">
                    <Grid.Row columns={2}>
                      <ListingRow
                        label={t('Ages')}
                        value={`${selected?.ageMonthsMin} months - ${
                          selected?.ageMonthsMax / 12
                        } years`}
                      />
                      <ListingRow
                        label={t('License number')}
                        value={selected?.licenseNumber}
                      />
                    </Grid.Row>

                    <Grid.Row columns={2}>
                      <ListingRow
                        label={t('Hours')}
                        value={`${selected?.hoursRegularStart}:00am - ${
                          selected?.hoursRegularEnd - 12
                        }:00pm`}
                      />
                      <ListingRow label={t('Days')} value={t('Mon – Fri')} />
                    </Grid.Row>

                    <Divider />

                    <Grid.Row columns={2}>
                      <Grid.Column>
                        <Header as="h2" content={t('Philosophy')} />

                        <p>
                          {`Through individualized attention, low student-to-teacher
                        ratios, and a mixed-age environment, ${selected?.name}
                        nurtures students and helps them reach key developmental
                        milestones from the comfort of an in-home program.`}
                        </p>
                      </Grid.Column>
                    </Grid.Row>

                    <Divider />

                    <Grid.Row columns={2}>
                      <Grid.Column>
                        <Header as="h2" content={t('Location')} />

                        <p>
                          {`${selected?.name} is located in a loving, welcoming home for the families of ${selected?.city}.`}
                        </p>
                      </Grid.Column>
                    </Grid.Row>

                    <Divider />

                    <Grid.Row columns={2}>
                      <Grid.Column>
                        <Header as="h2" content={t('Our Teacher')} />

                        <p>
                          {`Since June 1993, ${selected?.directorFirstName} ${selected?.directorLastName}’s goal has been to nurture and teach children in a personalized setting, all while creating an environment that families feel good about. For more information regarding licensing information for ${selected?.name}, please visit the Virginia Child Care Licensing website.`}
                        </p>
                      </Grid.Column>
                    </Grid.Row>

                    {/* <Grid.Row columns={2}>
                    <code>
                      TODO: render all fields.
                      <pre>{JSON.stringify(selected, null, 2)}</pre>
                    </code>
                  </Grid.Row> */}
                  </Grid>
                </Grid.Row>
              </Grid.Column>

              <Grid.Column width={6}>
                <Grid.Row>
                  <Segment
                    style={{
                      borderWidth: '0px',
                      boxShadow: 'none',
                      paddingTop: '30px',
                      paddingBottom: '30px',
                      marginBottom: '20px',
                    }}
                  >
                    <Header>{t('$200 - $1200')}</Header>
                    <br />

                    {renderAddToFavorite()}

                    <Button
                      basic
                      fluid
                      size="large"
                      className="action-button"
                      icon
                      labelPosition="left"
                    >
                      <Icon name="comments outline" />
                      {t('Message director')}
                    </Button>

                    <ApplyToEnrollButton />
                  </Segment>
                </Grid.Row>
              </Grid.Column>
            </Grid>
          </Segment>
        </Container>
      </div>
    </ContentContainer>
  );
};

export default ListingDetail;
