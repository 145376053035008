import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { size } from 'lodash-es';
import { Button, Label, Menu, Modal } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

// Import api & hooks.
import useAuth from '../../hooks/useAuth';
import useApplication from '../../hooks/useApplication';

// Import component.
import FavoritesList from './FavoritesList';

const FavoritesCart = () => {
  const [open, setOpen] = useState(false);

  const { auth } = useAuth();
  const { favorites } = useApplication();
  const history = useHistory();

  const { t } = useTranslation();

  if (!size(favorites)) return null;

  const trigger = favorites && size(favorites) && (
    <Menu.Item as="a">
      {t('Favorites')}
      <Label color="red" circular>
        {size(favorites)}
      </Label>
    </Menu.Item>
  );

  return auth?.currentUser?.uid ? (
    <Modal
      closeOnEscape={false}
      closeOnDimmerClick={false}
      centered={false}
      dimmer={false}
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      trigger={trigger}
      size="small"
      closeIcon
    >
      <Modal.Header>{t('My Favorite Programs')}</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <FavoritesList presentation="list" />
        </Modal.Description>
      </Modal.Content>

      <Modal.Actions>
        <Button
          primary
          onClick={() => {
            setOpen(false);
            history.push('/applications');
          }}
        >
          {t('Apply to Enroll')}
        </Button>
      </Modal.Actions>
    </Modal>
  ) : null;
};

export default FavoritesCart;
