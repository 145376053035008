import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Segment, Step } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import useApplication from '../../hooks/useApplication';

const ApplicationSteps = () => {
  const history = useHistory();
  const { step } = useParams();
  const { isStepDone } = useApplication();
  const { t } = useTranslation();

  return (
    <Segment basic>
      <Step.Group ordered vertical>
        <Step
          active={step === undefined || step === 'getting-started'}
          completed={isStepDone('gettingStarted')}
          onClick={() => history.push('/applications/new/getting-started')}
        >
          <Step.Content>
            <Step.Title>{t('Getting Started')}</Step.Title>
          </Step.Content>
        </Step>
        <Step
          active={step === 'assistance'}
          completed={isStepDone('assistance')}
          disabled={!isStepDone('gettingStarted')}
          onClick={() => history.push('/applications/new/assistance')}
        >
          <Step.Content>
            <Step.Title>{t('Assistance')}</Step.Title>
          </Step.Content>
        </Step>
        <Step
          active={step === 'children-information'}
          completed={isStepDone('childrenInformation')}
          disabled={!isStepDone('assistance')}
          onClick={() => history.push('/applications/new/children-information')}
        >
          <Step.Content>
            <Step.Title>{t('Children Information')}</Step.Title>
          </Step.Content>
        </Step>
        <Step
          active={step === 'statistical-information'}
          completed={isStepDone('statisticalInformation')}
          disabled={!isStepDone('childrenInformation')}
          onClick={() =>
            history.push('/applications/new/statistical-information')
          }
        >
          <Step.Content>
            <Step.Title>{t('Statistical Information')}</Step.Title>
          </Step.Content>
        </Step>
      </Step.Group>
    </Segment>
  );
};

export default ApplicationSteps;
