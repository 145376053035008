import React from 'react';
import { Input } from 'semantic-ui-react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import useAuth from '../hooks/useAuth';

const SearchInput = () => {
  const { isAuthenticated } = useAuth();
  const { pathname } = useLocation();
  const { t } = useTranslation();

  if (isAuthenticated && pathname === '/dashboard') return null;

  return <Input placeholder={t('Search location')} />;
};

export default SearchInput;
